import React from 'react'
import Image from 'gatsby-image';
import { graphql } from "gatsby";
import Layout from '../components/layout'
// import SocialMedia from '../components/socialmedia'
// import oracleOCP8 from './oracle-certified-professional-java-se-8-programmer_210x210.png'
// import oracleAwsSoftwareDeveloper from './aws_certified_developer.png'
// import oracleAwsSolutionsArchitect from './aws_certified_solutions_architect_associate.png'
import downloadThesisFile from './crudolfs_thesis.pdf'

const AboutPage = ({ data }) => (
  <Layout>
      <Image className="photo" fixed={data.photoAboutMeFixed.childImageSharp.fixed} />
      <h3>About Me </h3>
      {/* <SocialMedia/> */}
      <p>
        I'm Christiaan Rudolfs, born in 1979 in a small city in the Netherlands called Zevenaar.
        In 1997 I started a Computer Science study at the Radboud University of Nijmegen which I successfully completed in 2002 and since then
        I'm a Master of Computer Science.
      </p>
      <p>
          Currently I live in Den Bosch, the Netherlands, with my beautiful wife and our three gorgeous teenage kids. When I'm not
          working or in my spare time exploring new technologies, I like to spend time with my family and friends. I'm also a fanatic padel
          player. <a href="https://www.youtube.com/watch?v=IXJlMd29m44" target="_blank" rel="noopener noreferrer">Padel</a> is the fastest growing racket
          sport in the world which is only played in doubles. I love this dynamic sport because of its social and team play aspects and you also have to
          be creative and play tactical to win.
      </p>
      <h5>Senior Full Stack Java / Kotlin Developer</h5>
      <p>
        Professionally, I'm focused on backend development in Java and Kotlin with Spring (Boot), Java EE or Vert.x, but I also enjoy to develop
        frontend applications in e.g. Angular, React or Gatsby. In the cloud I'm most experienced on the AWS cloud platform, but I'm open to other cloud platforms as well. I hold
        two AWS certifications: <b><a href="https://www.credly.com/badges/28f8cc66-d7a8-4943-b5b9-05007159448e" target="_blank" rel="noopener noreferrer">AWS Certified Developer</a></b> and <b><a href="https://www.credly.com/badges/c541e519-65d6-48f4-ae83-977074d6eaf7" target="_blank" rel="noopener noreferrer">AWS Certified Solutions Architect</a></b>.
        In addition I'm of course also an <b><a href="https://www.youracclaim.com/badges/9ad168f1-386e-438e-925b-f09adcdc065e/linked_in_profile" target="_blank" rel="noopener noreferrer">Oracle Certified Java 8 Professional</a></b>.
        My believe is we should use Kotlin more and more, therefore I got certified in <b><a href="https://coursera.org/share/79aba81c226e0bac198d558b67f53a7e" target="_blank" rel="noopener noreferrer">Kotlin for Java Developers</a></b>.
        Highly recommended to other Java developers!
        <br/>
        <br/>
        As a developer I embrace Clean Architecture, Domain Driven Design, Test Driven Development and Clean Code (SOLID) principles in order to ensure software quality and good software
        development craftsmanship. I'm an advocate of the DevOps culture and Agile way of working within teams. I'm a (social) communicator and
        problem solver. I'm eager to learn new things and keep a close eye on new technologies. I'm energized by developing good solutions
        that add business value and can evolve and adapt easily to change.
        <br/>
        <br/>
        From here on I'll inform you about how my career as a Software Developer has gone so far...
      </p>
      <h5>Master of Computer Science - graduation assignment</h5>
      <p>
        I started my first professional Java programming job back in 2002. During my graduation assignment
        at <a href="https://howard-solutions.co.uk/edmond.php" target="_blank" rel="noopener noreferrer">Edmond Document Solutions</a> I conducted a study
        with the aim to develop a machine learning algorithm that enables a highly accurate automatic and incremental classification of emails based on the
        textual content. Besides the research I developed the E-Sl@ve system (in Java) that classifies email messages via an incremental learning process
        (which means that the system learns from user feedback) with a high accuracy. The core of E-Sl@ve consists of a self-developed machine learning
        algorithm. The research is published in my <a href={downloadThesisFile} target="_blank" rel="noopener noreferrer" download>masters thesis</a>{``}.
      </p>
      <h5>Period: 2002 - 2006</h5>
      <p>
        In the second half of 2002 I started my first employment at <a href="https://www.smile.nl/actueel/smile-onderdeel-van-kader" target="_blank" rel="noopener noreferrer">Smile Software</a> as a full stack
        software developer. I introduced J2EE and Rich Internet Applications (RIA) technology within the organization. We built a new complaint management
        system with a backend that was based on J2EE with JRun, Hibernate (ORM) and support for multiple relational database systems like Microsoft SQL Server,
        IBM DB2 and Oracle 8/9i. The frontend was based on Flash MX (programmed in ActionScript) and Adobe Flex. The product was sold to many companies in
        different branches like banking, transport/logistics, travel agencies and Dutch municipalities. I had a great time working at Smile Software,
        it felt more or less like a family to me, but after 4 years I decided it was time to move on.
      </p>
      <h5>Period: 2006 - 2013</h5>
      <p>
        In 2006 I joined Everest (currently known as <a href="https://www.blueriq.com/" target="_blank" rel="noopener noreferrer">Blueriq</a>). Here I worked my way up to the level of
        Senior Java Developer and finally also Java Architect. I worked for many clients in different branches (banking, government, retail) developing applications
        that leverage complex business rules and knowledge to drive vital business processes. In my first project all products were developed in Java (6) based on
        the J2EE standard using Enterprise Java Beans (EJB 2.0). But in my subsequent projects, the products were developed in the Spring Framework, which was
        a great programming experience at the time when we exchanged the heavyweight EJBs for the more lightweight POJO-based Spring Framework. At Everest I also
        got the chance to develop my social skills by participating in a one year traject <i>High Impact Personal Development Traject - Soft Skills</i>. This was
        a great learning experience and has made me a more mature Senior Software Developer. After almost 7 fruitful years I decided to leave Everest and start
        a new international challenge at Quintiq.
      </p>
      <h5>Period: 2013 - 2017</h5>
      <p>
        I joined <a href="https://www.3ds.com/products-services/delmia/products/delmia-quintiq/" target="_blank" rel="noopener noreferrer">Quintiq</a> (currently
        DELMIA Quintiq) in 2013 as a Senior Integration Consultant. Quintiq's mission is to solve the world's planning puzzles with a single platform.
        For me it was a great international experience in which I participated in many projects around the world. My first assignment was a workforce
        planning puzzle for <a href="https://www.cph.dk/en" target="_blank" rel="noopener noreferrer">Copenhagen Aiport</a>,
        (see <a href="https://www.3ds.com/fileadmin/PRODUCTS-SERVICES/QUINTIQ/PDF/Case-Study-PDF/copenhagen-airport-delmia-quintiq-case-study.pdf" target="_blank" rel="noopener noreferrer">case study</a>).
        Besides the integration with their HR system I also contributed to the development of a REST API that was leveraged
        by a third party mobile app used by their employees. For this project we worked closely with our development center in Kuala Lumpur, Malaysia. Great people to
        work with and during the project we've spent several weeks in Malaysia to deliver a good solution.
        <br/>
        <br/>
        The next project I joined
        was <a href="http://www.vandenbosch.com/" target="_blank" rel="noopener noreferrer">Van Den Bosch Transporten</a>. Here we built a logistics inter-modal
        transport planning solution for an international logistics services provider that transports liquid and dry bulk products for both the food and non-food
        industry (see <a href="https://www.3ds.com/fileadmin/PRODUCTS-SERVICES/QUINTIQ/PDF/Case-Study-PDF/van-den-bosch-transporten-delmia-quintiq-case-study.pdf" target="_blank" rel="noopener noreferrer">case study</a>).
        I contributed to the development of many system integrations and also the Quintiq mobile app with GPS tracking support (native both on Android and iOS).
        <br/>
        <br/>
        In 2014 I started on another workforce planning puzzle
        for <a href="https://www.atns.com/" target="_blank" rel="noopener noreferrer">Air Traffic Navigation Services (ATNS)</a> of South Africa.
        I travelled many times to Jonannesburg in South Africa to discuss the desired architecture and design for the required system integrations.
        It was a great experience working with a different culture.
        <br/>
        In between I provided cloud service consultancy to PostNL, in order to support
        the migration of their current planning system to a cloud environment.
        <br/>
        <br/>
        In 2015 I started another workforce planning solution to plan 8000 employees within DHL Express
        distributed over several hubs world wide. This solution used IBM Websphere MQ to facilitate system integration with many other systems. For this project
        I visited Kuala Lumpur, Malaysia again in order to develop a great solution together with the local colleagues. Also in 2015
        I trained several key administrator users at <a href="https://www.redbeemedia.com/" target="_blank" rel="noopener noreferrer">Red Bee Media (London)</a> on how to
        administer their workforce planning solution.
        <br/>
        <br/>
        In 2016 I started a new project
        for <a href="https://www.drax.com/" target="_blank" rel="noopener noreferrer">Drax</a> where we've built a planning solution for vessel discharges and (inland) inventory
        movements of biomass and coal products to Drax Power Stations (UK). This project went really smooth due to great cooperation with Drax and went live within time
        and budget (read <a href="https://www.3ds.com/products-services/delmia/resources/drax/" target="_blank" rel="noopener noreferrer">here</a>).
        <br/>
        <br/>
        My final project at Quintiq was a planning solution for pipeline and rail scheduling of oil products between terminals
        for <a href="https://www.orlen.pl/en/" target="_blank" rel="noopener noreferrer">PKN Orlen</a>, a big Polish oil company. Here we integrated the planning solution
        with SAP and many other custom systems. A great project that also went live successfully
        (see <a href="https://www.hydrocarbonengineering.com/petrochemicals/30062017/pkn-orlen-selects-quintiq-solution/" target="_blank" rel="noopener noreferrer">announcement</a>).
        <br/>
        <br/>
        After more than 3 years I gained a lot of international experience in solving the worlds planning puzzles in sometimes very complex environments with smart
        colleagues. Thanks Quintiq, for having this great time together!
      </p>
      <h5>Period: 2017 - now</h5>
      <p>
        In 2017 I started as a semi-independent Senior Java Developer at <a href="https://www.pancompany.com/" target="_blank" rel="noopener noreferrer">PanCompany</a>.
        In my first assignment I was hired
        by <a href="https://www.malmberg.nl/" target="_blank" rel="noopener noreferrer">Malmberg</a> (a <a href="https://sanomalearning.com/" target="_blank" rel="noopener noreferrer">Sanoma Learning</a> company).
        For a period of 4 years I helped to shape the future of
        digital (primary and secundary) education as a Senior Java Developer. Besides that I was also involved in many architectural decisions. We developed several
        solutions within Malmberg. All applications and services that we've built were cloud-native and use Amazon Web Services (AWS) as a cloud computing platform.
        One of the solutions is a new platform
        for primary and secondary education that offers a variety of learning applications to many students and teachers in the Netherlands, Belgium, Finland and Sweden.
        The technical stack was based on a <a href="https://www.reactivemanifesto.org/" target="_blank" rel="noopener noreferrer">Reactive Architecture</a> were we
        used <a href="https://www.reactive-streams.org/" target="_blank" rel="noopener noreferrer">Reactive Programming</a> with <a href="https://openjdk.java.net/projects/jdk8/" target="_blank" rel="noopener noreferrer">Java 8</a>
        , <a href="https://openjdk.java.net/projects/jdk/11/" target="_blank" rel="noopener noreferrer">Java 11</a>
        , <a href="https://github.com/ReactiveX/RxJava" target="_blank" rel="noopener noreferrer">RxJava</a>, <a href="https://vertx.io/" target="_blank" rel="noopener noreferrer">Vert.x</a>
        , <a href="https://spring.io" target="_blank" rel="noopener noreferrer">Spring (Boot)</a>
        , <a href="https://www.mongodb.com/atlas/database" target="_blank" rel="noopener noreferrer">MongoDB</a> and <a href="https://aws.amazon.com/neptune/" target="_blank" rel="noopener noreferrer">Amazon Neptune</a> (graph
        database) in the backend and <a href="https://angular.io/" target="_blank" rel="noopener noreferrer">Angular</a> for the frontend. The solution is deployed on the
        AWS cloud (EC2).
        <br/>
        <br/>
        Another solution that we've built is the Publication Suite, which is responsible for publishing and previewing of learning content to Customer Facing Applications.
        At the start of 2018 we started developing the Publication Suite in order to replace functionality that was currently offered by a legacy application that was unstable, unreliable and not scalable.
        We decided to structure our new solution as a collection of services and applied a Microservice architecture. All microservices were built in Java 11 with Vert.x or Spring Boot and
        use AWS serverless services like S3, DynamoDB and SQS. The microservices ship as Docker images and are deployed on AWS, distributed over multiple EC2 nodes and communicate through
        a clustered Vert.x eventbus. We've used AWS DevOps services for Continuous Integration and Delivery: CodePipeline, CodeBuild and CodeDeploy. The final result is
        an application that is highly available, extremely scalable and super reliable. It was taken into production before start of the (Dutch) schoolyear in June 2018.
        End users are really happy as their perceived response times for content publications have reduced enormously.
        <br/>
        <br/>
        At the start of 2019 we were asked to build a (scalable) Virus Scanner solution around a third party virus scanner product. We decided to go serverless and used
        the <a href="https://serverless.com/" target="_blank" rel="noopener noreferrer">Serverless framework</a> and multiple AWS Serverless solutions, like AWS API
        Gateway, Lambda, DynamoDB, S3 and SQS, to build this solution.
        <br/>
        <br/>
        During my 4 years at Malmberg I learned a lot and gained valuable experience in several areas. One of these areas is the wonderful world of Reactive Programming (in Java).
        This has quite a steep learning curve, but is extremely powerful once you grasp the concepts, apply it where useful and really start working with it.
        I decided to conduct a workshop about Reactive Programming within PanCompany (mid 2019) to deepen my knowledge and share this with colleagues. This was a great experience,
        the slides of the result can be found in the following
        presentation: <a href="https://github.com/crudolfs/reactive-workshop" target="_blank" rel="noopener noreferrer">Thinking Reactive</a>.
        <br/>
        <br/>
        Another area that I've gained
        a lot of experience is building cloud-native applications on AWS. In order to strengthen my knowledge on AWS I decided to educate myself (successfully) as an AWS
        Certified Developer and AWS Certified Solutions Architect. I'm excited to develop more cloud-native (Java) applications on AWS!
      </p>
      {/* <h3>Certifications</h3>
      <Container>
        <Row>
          <Col>
            <img className="certbadge" src={oracleOCP8} alt="" />
          </Col>
          <Col>
            <img className="certbadge" src={oracleAwsSoftwareDeveloper} alt="" />
          </Col>
          <Col>
            <img className="certbadge" src={oracleAwsSolutionsArchitect} alt="" />
          </Col>
        </Row>
      </Container> */}
  </Layout>
)

export default AboutPage

export const pageQuery = graphql`
  query {
    photoAboutMeFluid: file(relativePath: { eq: "images/selfie_small.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 200, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    photoAboutMeFixed: file(relativePath: { eq: "images/selfie_small.jpg" }) {
      childImageSharp {
        fixed(width: 150) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;
